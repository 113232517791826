/*
    A service used to trigger Omniture events
*/
webClient.factory('omnitureService', ['$window',
    function ($window) {

        return {

            SEARCH_PAGE: "Recherche:Vol",
            FLIGHT_TUNNEL: "TUNNEL VOL",
            FLIGHT_CONFIRMATION: "CONF COMMANDE VOL-Voyage",

            PURCHASE_1_VOL: "PURCHASE 1 VOL",
            PURCHASE_2_VOL: "PURCHASE 2 VOL",
            PURCHASE_3_VOL: "PURCHASE 3 VOL",
            PURCHASE_4_VOL: "PURCHASE 4 VOL",
            CONFIRMATION: "CONFIRMATION COMMANDE",

            isActivated: function () {
                return false;
                //return (typeof s !== 'undefined' && s != null);
            },
            sendCdtm: function (pageName, data) {

                configureCdtm();

                var siteId = "100";
                 if (isMobile) {
                     siteId = "206";
                }

                cdtm.addVariable(
                    "pgName", [0, 1]
                );
                cdtm.addVariable(
                    "siteId", [0, 1]
                );
                cdtm.addVariable(
                    "type", [0, 1]
                );

                if (pageName == this.SEARCH_PAGE) {
                    var req = $scope.requestSummary;
                    var filtersValues = $scope.view.appliedFiltersValue;

                    var facets = Object.entries($scope.view.appliedFilters).filter(function (facet) { return facet[1] == true })
                        .reduce(function (facets, facet) {
                            var value = $scope.view.appliedFiltersValue[facet[0]];
                            if (typeof (value) === 'undefined') {
                                if (facet[0] == 'marketingCarrier') {
                                    value = filtersValues['marketingCarriers'];
                                }
                                if (facet[0] == 'departureIATA') {
                                    value = filtersValues.outbound.departure;
                                }
                                if (facet[0] == 'arrivalIATA') {
                                    value = filtersValues.outbound.arrival;
                                }
                                if (facet[0] == 'departureTime') {
                                    value = {
                                        "outbound": filtersValues.outbound.departureTime
                                    }
                                    if (!req.oneWay) {
                                        value.inbound = filtersValues.inbound.departureTime;
                                    }
                                }
                                if (facet[0] == 'arrivalTime') {
                                    value = {
                                        "outbound": filtersValues.outbound.arrivalTime
                                    }
                                    if (!req.oneWay) {
                                        value.inbound = filtersValues.inbound.arrivalTime;
                                    }
                                }
                            }
                            facets.push({ "type": facet[0], "value": value }); return facets
                        }, []);

                    cdtm.addVariable(
                        "sorting", [0, 1]
                    );
                    cdtm.addVariable(
                        "query", [0, 1]
                    );
                    cdtm.addVariable(
                        "facets", [0, 1]
                    );

                    cdtm.setValueBulk({
                        "pgName": this.SEARCH_PAGE,
                        "type": "SEARCH",
                        "facets": facets,
                        "sorting": $scope.sortingPriority,
                        "query": {
                            "dest": req.destination.cityName,
                            "depCity": req.origin.cityName,
                            "depDate": req.goDate.dateString,
                            "retDate": req.oneWay ? '' : req.goDate2.dateString,
                            "fClass": req.cabinClass,
                            "ftype": req.oneWay ? 'aller simple' : (req.roundTrip ? 'aller-retour' : 'open jaw'),
                            "passengers": req.totalPaxes
                        }
                    });
                }

                if (pageName == this.PURCHASE_1_VOL || pageName == this.PURCHASE_2_VOL || pageName == this.PURCHASE_3_VOL) {
                    cdtm.addVariable(
                        "subType", [0, 1]
                    );
                    cdtm.addVariable(
                        "orderStep", [0, 1]
                    );
                    cdtm.addVariable(
                        "price", [0, 1]
                    );
                    cdtm.addVariable(
                        "dest", [0, 1]
                    );
                    cdtm.addVariable(
                        "depCity", [0, 1]
                    );
                    cdtm.addVariable(
                        "sellerId", [0, 1]
                    );
                    cdtm.addVariable(
                        "quantity", [0, 1]
                    );
                    cdtm.setValueBulk({
                        "pgName": this.PURCHASE_1_VOL,
                        "type": "ORDER_PROCESS",
                        "subType": "PASSENGERS_AND_OPTIONS",
                        "orderStep": 1,
                        "price": data.purchaseResponse.proposals[0].totalAmount,
                        "dest": data.request.destination.cityName,
                        "depCity": data.request.origin.cityName,
                        "sellerId": data.purchaseResponse.platingCarrier,
                        "quantity": data.request.totalPaxes
                    });
                }

                if (pageName == this.PURCHASE_2_VOL) {
                    scopeToSend = cdtm.Scopes.TUNNEL_2;
                    cdtm.addVariable(
                        "subType", [0, 1]
                    );
                    cdtm.addVariable(
                        "orderStep", [0, 1]
                    );
                    cdtm.setValueBulk({
                        "pgName": this.PURCHASE_2_VOL,
                        "orderStep": 2,
                        "subType": "INSURANCE"
                    });
                }

                if (pageName == this.PURCHASE_3_VOL) {
                    var paymentChoices = data.paymentMethods.reduce(function (paymentMethods, paymentMethod) {
                        paymentMethods.push(paymentMethod.paymentMethodName);
                        return paymentMethods;
                    }, []);
                    if ($window.enableHolidaysVoucher && data.prixTotal.value > 0) {
                        paymentChoices.push("ANCV");
                    }
                    cdtm.addVariable(
                        "subType", [0, 1]
                    );
                    cdtm.addVariable(
                        "orderStep", [0, 1]
                    );
                    cdtm.addVariable(
                        "paymentChoices", [0, 1]
                    );
                    cdtm.setValueBulk({
                        "pgName": this.PURCHASE_3_VOL,
                        "orderStep": 3,
                        "subType": "PAYMENT_CHOICES",
                        "paymentChoices": paymentChoices
                    });
                }

                if (pageName == this.FLIGHT_CONFIRMATION) {
                    cdtm.addVariable(
                        "price", [0, 1]
                    );
                    cdtm.addVariable(
                        "dest", [0, 1]
                    );
                    cdtm.addVariable(
                        "depCity", [0, 1]
                    );
                    cdtm.addVariable(
                        "sellerId", [0, 1]
                    );
                    cdtm.addVariable(
                        "quantity", [0, 1]
                    );
                    cdtm.setValueBulk({
                        "pgName": this.FLIGHT_CONFIRMATION,
                        "type": "PURCHASE",
                        "price": data.purchaseResponse.proposals[0].totalAmount,
                        "dest": data.request.destination.cityName,
                        "depCity": data.request.origin.cityName,
                        "sellerId": data.purchaseResponse.platingCarrier,
                        "quantity": data.request.totalPaxes
                    });
                }

                cdtm.setValue("siteId", siteId);
                console.info("prepare message ------> ");
                var scopeToSend = [0];
                if (window.sessionStorage.getItem('acceptedOptionalCookies') == 'true') {
                    scopeToSend = [0, 1];
                }
                console.info("scope to send is : " + scopeToSend);
                console.info(cdtm.prepareMessage(scopeToSend));
                cdtm.send(window.cdtmUrl, scopeToSend,
                    function () { console.info("Success on sending data to CDTM !!! "); },
                    function (e) { console.error("Error while sending data to CDTM !!!!! ", e); });
            },
            sendEvent: function (pageName, prop7, data) {

                if (typeof s !== 'undefined' && s != null) {

                    console.log("pageName: " + pageName);
                    console.log("prop7: " + prop7);

                    s.pageName = pageName;
                    s.prop7 = prop7;
                    s.prop2 = "Voyage";
                    if (pageName === 'HomeHotel') {
                        s.prop3 = "Hôtel";
                    }
                    else {
                        s.prop3 = "Vol";
                    }
                    s.prop24 = "1";

                    var cdiscountLoginGuid = $window.sessionStorage.getItem("cdiscountCustomerGuid");

                    //console.log("cdiscountLoginGuid: " + cdiscountLoginGuid);

                    if (isTablet) {
                        s.eVar93 = 'Tablet';
                    }
                    else if (isMobile) {
                        s.eVar93 = 'SMS';
                    }
                    else {
                        s.eVar93 = 'PC';
                    }

                    //if (typeof cdiscountLoginGuid !== 'undefined' && cdiscountLoginGuid != null) {
                    //    s.eVar58 = cdiscountLoginGuid;
                    //}

                    if (pageName == this.SEARCH_PAGE) {

                        var request = data;

                        s.eVar9 = this.getEvar9(prop7, request);
                        s.eVar105 = this.getEvar105(prop7, request);
                        s.eVar106 = this.getEvar106(prop7, request);

                        s.events = "event1";

                        //criteo
                        this.setViewSearch(request);
                        this.pushCriteo({ event: "viewList", item: [request.origin.cityIATA + '-' + request.destination.cityIATA], ui_section: "vols" }, this.getViewSearch());

                        var endDate = request.oneWay ? '' : moment(request.itineraries.last().goDate.date).format("YYYY-MM-DD");

                        console.log(" GOOGLE TAG ENDDATE !! == > " + endDate);
                        // Google TAG
                        if (typeof gtag !== 'undefined' && gtag != null) {
                            gtag('event', 'view_search_results', {
                                'items': [{
                                    'origin': request.origin.cityIATA,
                                    'destination': request.destination.cityIATA,
                                    'start_date': moment(request.goDate.date).format("YYYY-MM-DD"),
                                    'end_date': endDate,
                                    'google_business_vertical': 'flights'
                                }]
                            });
                        }
                    }
                    else if (pageName == this.PURCHASE_1_VOL) {

                        console.log(" GOOGLE TAG  data is already request  1!! == > " + JSON.stringify(data));

                        var request = data.request;
                        var price = data.totalAmount;

                        console.log(" GOOGLE TAG  data is already request 2 !! == > " + JSON.stringify(request));

                        $window.sessionStorage.setItem('omnitureProductId', this.getProduct(prop7, request, price));
                        console.log(" prb 12");

                        $window.sessionStorage.setItem('omnitureProductId', this.getProduct(prop7, request, price));
                        console.log(" prb 13");

                        s.products = $window.sessionStorage.getItem('omnitureProductId');
                        s.events = "event248,scView";

                        console.log(" prb 14");

                        //criteo
                        this.setViewBasket(request, price);
                        console.log(" prb 15");

                        this.pushCriteo(this.getViewSearch(), this.getViewBasket());

                        console.log(" prb 16");

                        var endDate = request.oneWay ? '' : moment(request.itineraries.last().goDate.date).format("YYYY-MM-DD");

                        console.log(" prb 17");

                        // Google TAG

                        var originAirport = $window.$scope.bkgData.purchaseResponse.itineraries.first().origin.airportIata;
                        var destinationAirport = $window.$scope.bkgData.purchaseResponse.itineraries.last().destination.airportIata;

                        console.log(" GOOGLE TAG ENDDATE !! == > " + endDate);
                        console.log(" GOOGLE TAG originAirport !! == > " + originAirport);
                        console.log(" GOOGLE TAG destinationAirport !! == > " + destinationAirport);


                        if (typeof gtag !== 'undefined' && gtag != null) {

                            gtag('event', 'add_to_cart', {
                                'value': price,
                                'items': [{
                                    'origin': originAirport,
                                    'destination': destinationAirport,
                                    'start_date': moment(request.goDate.date).format("YYYY-MM-DD"),
                                    'end_date': endDate,
                                    'google_business_vertical': 'flights'
                                }]
                            });
                        }

                    }
                    else if (pageName == this.PURCHASE_2_VOL) {

                        s.products = $window.sessionStorage.getItem('omnitureProductId');
                        s.events = "event249";

                        //criteo
                        this.pushCriteo(this.getViewSearch(), this.getViewBasket());
                    }
                    else if (pageName == this.PURCHASE_3_VOL) {

                        s.products = $window.sessionStorage.getItem('omnitureProductId');
                        s.events = "event250";

                        //criteo
                        if ($window.$scope.bkgData.prixTotal) {
                            this.updateViewBasket($window.$scope.bkgData.prixTotal);
                        }
                        this.pushCriteo(this.getViewSearch(), this.getViewBasket());
                    }
                    else if (pageName == this.PURCHASE_4_VOL) {

                        s.products = $window.sessionStorage.getItem('omnitureProductId');
                        s.events = "event251";

                        //criteo
                        this.pushCriteo(this.getViewSearch(), this.getViewBasket());

                    }
                    else if (pageName == this.FLIGHT_CONFIRMATION) {

                        s.products = $window.sessionStorage.getItem('omnitureProductId');
                        s.events = "purchase";

                        //criteo
                        this.pushCriteo(this.getViewSearch(), this.getTrackTransaction($window.$scope.bkgData.bookingResponse.numbers[0].bookingNumber));

                        var endDate = $window.$scope.bkgData.request.oneWay ? '' : moment($window.$scope.bkgData.request.itineraries.last().goDate.date).format("YYYY-MM-DD");

                        // Google TAG

                        var originAirport = $window.$scope.bkgData.purchaseResponse.itineraries.first().origin.airportIata;
                        var destinationAirport = $window.$scope.bkgData.purchaseResponse.itineraries.last().destination.airportIata;


                        if (typeof gtag !== 'undefined' && gtag != null) {
                            gtag('event', 'purchase', {
                                'value': $window.$scope.bkgData.prixTotal.value,
                                'items': [{
                                    'origin': originAirport,
                                    'destination': destinationAirport,
                                    'start_date': moment($window.$scope.bkgData.request.goDate.date).format("YYYY-MM-DD"),
                                    'end_date': endDate,
                                    'google_business_vertical': 'flights'
                                }]
                            });

                            var isCdav = 'NON'
                            if ($window.sessionStorage.getItem("hasCdav")) {
                                isCdav = $window.sessionStorage.getItem("hasCdav") == "true" ? 'OUI' : 'NON';
                            }

                            var voucher = '';

                            if ($window.$scope.bkgData.bookingRequest.discountCode) {
                                voucher = window.$scope.bkgData.bookingRequest.discountCode;
                            }

                            var nombreEscales = 0;
                            var itineraries = $window.$scope.bkgData.purchaseResponse.itineraries;

                            for (var i = 0; i < itineraries.length; i++) {
                                nombreEscales += itineraries[i].segments.length - 1;
                            }

                            gtag('event', 'conversion', {
                                'send_to': 'AW-938777661/QbG8CIOe4Y8BEL240r8D',
                                'value': $window.$scope.bkgData.prixTotal.value,
                                'currency': 'EUR',
                                'transaction_id': $window.$scope.bkgData.bookingResponse.numbers[0].bookingNumber,
                                'type_paiement': $window.$scope.bkgData.bookingRequest.paymentMethod.paymentMethodReference,
                                'is_cdav': isCdav,
                                'categorie': 'VOL',
                                'iata_origine': $window.$scope.bkgData.purchaseResponse.itineraries[0].origin.airportIata,
                                'pays_origine': $window.$scope.bkgData.request.origin.countryName,
                                'pays_destination': $window.$scope.bkgData.request.destination.countryName,
                                'ville_origine': $window.$scope.bkgData.request.origin.cityName,
                                'ville_destination': $window.$scope.bkgData.request.destination.cityName,
                                'id_produit': $window.$scope.bkgData.request.origin.cityIATA + '-' + $window.$scope.bkgData.request.destination.cityIATA,
                                'date_depart': moment($window.$scope.bkgData.request.goDate.date).format("YYYY-MM-DD"),
                                'date_retour': endDate,
                                'type_client': 'type_client',
                                'code_promo': voucher,
                                'compagnie': $window.$scope.bkgData.purchaseResponse.itineraries[0].marketingCarrier.iata,
                                'nombre_escale': nombreEscales
                            });
                        }

                        //this.pushGA($window.$scope.bkgData.prixTotal.value, $window.$scope.bkgData.bookingResponse.numbers[0].bookingNumber);
                    }


                    var params = this.getUrlVars();

                    if (typeof params['event'] !== 'undefined' && params['event'] != null) {
                        s.events +=  ',' + params['event'];
                    }

                    s.t();  //fires tracking
                }

                //AWIN convertion traker
                if (pageName == this.FLIGHT_CONFIRMATION) {

                    var AWIN = {};
                    AWIN.Tracking = {};
                    AWIN.Tracking.Sale = {};

                    var voucher = '';

                    if ($window.$scope.bkgData.bookingRequest.discountCode) {
                        voucher = window.$scope.bkgData.bookingRequest.discountCode;
                    }

                    /*** Set your transaction parameters ***/
                    AWIN.Tracking.Sale.amount = $window.$scope.bkgData.prixTotal.value;//"totalAmount";
                    AWIN.Tracking.Sale.channel = "aw";
                    AWIN.Tracking.Sale.currency = "EUR";

                    AWIN.Tracking.Sale.orderRef = $window.$scope.bkgData.bookingResponse.numbers[0].bookingNumber;//"orderReference";
                    AWIN.Tracking.Sale.parts = 'VOL:' + $window.$scope.bkgData.prixTotal.value;//"commissionGroup:totalAmount";
                    AWIN.Tracking.Sale.voucher = voucher;//"voucherCode";
                    AWIN.Tracking.Sale.test = "0";

                    var request = $window.$scope.bkgData.request;

                    var nb_adults = request.adults > 0 ? request.adults : 0;
                    var nb_childs = request.childs > 0 ? request.childs : 0;
                    var nb_infants = request.infants > 0 ? request.infants : 0;
                    var productid = request.origin.cityIATA + '-' + request.destination.cityIATA;
                    var dateDepart = moment(request.goDate.date).format("YYYY-MM-DD");
                    var dateRetour = request.oneWay ? '' : moment(request.itineraries.last().goDate.date).format("YYYY-MM-DD");
                    var compagnie = $window.$scope.bkgData.purchaseResponse.platingCarrier;

                    AWIN.Tracking.Sale.custom = [productid, dateDepart, dateRetour, nb_adults + "|" + nb_childs + "|" + nb_infants, compagnie];
                    AWIN.Tracking.Sale.customerAcquisition = 0;

                    $.getScript("https://www.dwin1.com/14021.js", function () { console.log('dwin script loaded') });

                    $('<img />', {
                        src: 'https://www.awin1.com/sread.img?tt=ns&tv=2&merchant=14021&amount='
                            + AWIN.Tracking.Sale.amount + '&ch=aw&cr=EUR&parts=' + AWIN.Tracking.Sale.parts +
                            '&ref=' + AWIN.Tracking.Sale.orderRef + '&vc=' + AWIN.Tracking.Sale.voucher
                            + '&p1=' + productid + '&p2=' + dateDepart + '&p3=' + dateRetour + '&p4=' + nb_adults + "|" + nb_childs + "|" + nb_infants
                            + '&p5=' + compagnie + '&customeracquisition' + AWIN.Tracking.Sale.customerAcquisition + '&testmode=0',
                        width: '0px',
                        height: '0px',
                        style: 'display: none;'
                    }).appendTo($('body'));
                }
                else {
                    var awMastertag = document.createElement("script");
                    awMastertag.setAttribute("defer", "defer");
                    awMastertag.src = "https://www.dwin1.com/14021.js";
                    awMastertag.type = "text/javascript";
                    document.getElementsByTagName("body")[0].appendChild(awMastertag);
                }

                //KAYAK
                if (pageName == this.FLIGHT_CONFIRMATION) {

                    var kayakclickid = $window.sessionStorage.getItem('kayakclickid');
                    if (!kayakclickid) {
                        return;
                    }
                    var KAYAK = {};
                    KAYAK.Tracking = {};
                    KAYAK.Tracking.Sale = {};
                    KAYAK.Tracking.Sale.amount = $window.$scope.bkgData.prixTotal.value;//"totalAmount";
                    KAYAK.Tracking.Sale.orderRef = $window.$scope.bkgData.bookingResponse.numbers[0].bookingNumber;//"orderReference";
                    KAYAK.Tracking.Sale.rand = Math.floor(Math.random() * Math.floor(10000));

                    $('<img />', {
                        src: 'https://www.kayak.com/s/kayakpixel/confirm/CDISCOUNT?kayakclickid=' + kayakclickid +'&price=' + KAYAK.Tracking.Sale.amount + '&currency=EUR&confirmation=' + KAYAK.Tracking.Sale.orderRef + '&rand=' + KAYAK.Tracking.Sale.rand,
                        width: '0px',
                        height: '0px',
                        style: 'display: none;'
                    }).appendTo($('body'));
                }
            },

            getProduct: function (prop7, r, price) {

                if (prop7 == this.FLIGHT_TUNNEL) {
                    var lastIti = r.itineraries[r.itineraries.length - 1];
                    var o = r.origin;
                    var d = r.multiDestination ? lastIti.destination : r.destination;
                    var quantity = "1";

                    return ";" + o.cityName + "/" + d.cityName + "/;" + quantity + ";" + price.value;
                }

            },

            getEvar9: function (prop7, r) {

                if (prop7 == this.SEARCH_PAGE) {
                    var lastIti = r.itineraries[r.itineraries.length - 1];
                    var o = r.origin;
                    var d = r.multiDestination ? lastIti.destination : r.destination;

                    return o.cityName + " - " + d.cityName;
                }
                
            },

            getEvar105: function (prop7, r) {

                if (prop7 == this.SEARCH_PAGE) {

                    var isMulti = r.multiDestination;
                    var lastIti = r.itineraries[r.itineraries.length - 1];
                    var lastDate = isMulti ? lastIti.goDate : r.goDate2;
                    if (!r.oneWay) { // no return date in one way
                        return r.goDate.dateString + " - " + lastDate.dateString;
                    }
                    return r.goDate.dateString;
                }
            },

            setViewSearch: function (r) {

                    var prop = { event: 'viewSearch' };

                    var isMulti = r.multiDestination;
                    var lastIti = r.itineraries[r.itineraries.length - 1];
                    var lastDate = isMulti ? lastIti.goDate : r.goDate2;
                    if (!r.oneWay) { // no return date in one way
                        prop.checkout_date = this.formatDate(lastDate.dateString);
                    }
                    prop.checkin_date = this.formatDate(r.goDate.dateString);

                    prop.nbra = r.adults > 0 ? r.adults : 0;
                    prop.nbrc = r.childs > 0 ? r.childs : 0;
                    prop.nbri = r.infants > 0 ? r.infants : 0;

                    $window.sessionStorage.setItem('criteoViewSearch', JSON.stringify(prop));
            },

            getViewSearch: function () {
                return JSON.parse($window.sessionStorage.getItem('criteoViewSearch'));
            },

            setViewBasket: function (r, price) {
                var prop = { event: "viewBasket", ui_section: "vols", item: [{ id: r.origin.cityIATA + '-' + r.destination.cityIATA , price: price.value, quantity: 1 }]};
                $window.sessionStorage.setItem('criteoViewBasket', JSON.stringify(prop));
            },

            updateViewBasket: function (price) {
                var prop = this.getViewBasket();
                prop.item[0].price = price.value;
                $window.sessionStorage.setItem('criteoViewBasket', JSON.stringify(prop));
            },

            getViewBasket: function () {
                return JSON.parse($window.sessionStorage.getItem('criteoViewBasket'));
            },

            getTrackTransaction: function (orderId) {
                var prop = JSON.parse($window.sessionStorage.getItem('criteoViewBasket'));
                return {
                    event: "trackTransaction", 
                    id: orderId, 
                    ui_section: "vols", 
                    item: prop.item
                };

            },

            getEvar106: function (prop7, r) {

                if (prop7 == this.SEARCH_PAGE) {
                    return r.adults + r.childs + r.infants;
                }
            },

            getCookie: function (name) {
                var value = "; " + document.cookie;
                var parts = value.split("; " + name + "=");
                if (parts.length == 2) return parts.pop().split(";").shift();
            },

            getUrlVars: function() {
                var vars = {};
                var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                    vars[key] = value;
                });
                return vars;
            },

            pushCriteo: function (arg1, arg2, arg3) {
                if (typeof window.criteo_q !== 'undefined' && window.criteo_q != null) {
                    window.criteo_q.push( { event: "setAccount", account: 52511 }, arg1, arg2, arg3);
                }
            },

            pushGA: function (price, orderId) {
                if (typeof gtag !== 'undefined' && gtag != null) {
                    gtag('event', 'conversion', {
                      'send_to': 'AW-938777661/QbG8CIOe4Y8BEL240r8D',
                      'value': price,
                      'currency': 'EUR',
                      'transaction_id': orderId
                  });
                }
            },

            //format a date from dd/MM/yyyy to yyyy-MM-dd
            formatDate: function (value) {
                return value.substring(6, 10) + '-' +  value.substring(3, 5) + '-' + value.substring(0, 2);
            }
        };
    }]);

